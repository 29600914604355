import type { RetryType } from "./constant";
import { retryStrMap } from "./constant";
import { logger } from "@ali/speedy3-redfox-tracker/es/index";

export function appendRetryFlag(src: string, retryType: RetryType) {
  let retryStr = retryStrMap[retryType];
  let queryString = src.split("?")[1];
  let srcUrl = src.split("?")[0];

  let params = new URLSearchParams(queryString);

  const autoRetry = params.get(retryStr);
  if (autoRetry) {
    params.set(retryStr, `${Number(autoRetry) + 1}`);
  } else {
    params.set(retryStr, "1");
  }
  queryString = params.toString();
  return `${srcUrl}?${queryString}`;
}

export function hasImgReloadOverMaxCount(src: string, retryType: RetryType) {
  let retryStr = retryStrMap[retryType];

  let queryString = src.split("?")[1];
  if (!queryString) {
    return false;
  }

  let params = new URLSearchParams(queryString);
  const autoRetry = params.get(retryStr);
  if (!autoRetry) {
    return false;
  }
  return Number(autoRetry) >= 3;
}

export function isImgElementFail(element: HTMLImageElement) {
  return element.complete && element.naturalWidth === 0;
}

export function isImgElementLoading(element: HTMLImageElement) {
  return !element.complete;
}

export function doLog(type: string, params = {}) {
  logger("click", "/sc.redfox.imgRetry", {
    type,
    ...params,
  });
}

export function doWrongImgLog(type: string, params = {}) {
  logger("click", "/sc.redfox.wrongImg", {
    type,
    ...params,
  });
}

export function doConsole(...args: any[]) {
  // 模拟console，方便调试，添加ts的类型入参
  // @ts-ignore
  if (__DEV__) console.log("[img重试]", ...arguments);
}
