import envIs from "@ali/speedy3-redfox-core/es/env/is";
import {
  onNetworkChange,
  networkTypeEnum,
} from "@ali/speedy3-redfox-adapter/es/app/index";
import debounce from "lodash/debounce";

import type { RetryType } from "./constant";
import { version } from "./constant";
import {
  appendRetryFlag,
  hasImgReloadOverMaxCount,
  isImgElementFail,
  doConsole,
  doLog,
  doWrongImgLog,
  isImgElementLoading,
} from "./utils";

export default function main() {
  // 非浏览器环境不进行处理
  // 确认该SDK不多次被引入，全局唯一
  if (hasInitGlobalStore() || !envIs.is.web) {
    return;
  }
  doConsole("启用SDK");
  doLog("init", { version });

  initGlobalStore();

  // 遍历已有图片
  doTraverseImgs();

  doConsole("增加监听");
  // 增加图片监听
  addImgListener();
}

function hasInitGlobalStore() {
  return !!(window as any)?.lib?.redfoxReloadImg;
}
const retryImgQueue: HTMLImageElement[] = [];
function initGlobalStore() {
  if (!(window as any)?.lib?.redfoxReloadImg) {
    (window as any).lib = (window as any).lib || {};
    (window as any).lib.redfoxReloadImg = {
      version,
      reloadCount: 0,
      retryQueue: retryImgQueue,
    };
  }
}

function doTraverseImgs() {
  const imgList = document.body.querySelectorAll("img");
  imgList.forEach((element) => {
    if (isImgElementFail(element)) {
      // lazyload图片不处理，loading=lazy也忽略不处理

      const src = element.getAttribute("src");
      const lazyLoading = element.getAttribute("loading");
      if (!src || lazyLoading === "lazy") {
        return;
      }
      pushRetryQueue(element);
    }
  });
}

function addImgListener() {
  // 监听网络状态
  onNetworkChange(({ type }) => {
    if (type === networkTypeEnum.WIFI || type === networkTypeEnum.CELL) {
      retryImgQueue.forEach((element) => doRetryImg(element, "networkChange"));
    }
  });

  // 监听图片加载错误
  document.body.addEventListener(
    "error",
    (e) => {
      const element = e.target;
      if (element instanceof HTMLImageElement) {
        pushRetryQueue(element);
      }
    },
    true,
  );

  // 监听性能变化来观测是否有图片加载成功，节流每1s只能触发一次
  const callback = function (mutationsList: any) {
    const entries = mutationsList.getEntries();

    for (let entryItem of entries) {
      if (entryItem.initiatorType === "img") {
        const { duration, decodedBodySize, name } = entryItem;
        if (
          duration > 100 &&
          decodedBodySize > 10 &&
          name.indexOf("mmstat.com") === -1
        ) {
          retryImgQueue.forEach((element) =>
            doRetryImg(element, "otherSuccess"),
          );
          // 在整个变化中，有一次执行即可
          return;
        }
      }
    }
  };
  const debounceCallback = debounce(callback, 1000);

  var observer2 = new PerformanceObserver(debounceCallback);
  observer2.observe({ entryTypes: ["resource"] });
}

function pushRetryQueue(element: HTMLImageElement) {
  const imgSrc = element.getAttribute("src");
  if (!imgSrc) {
    doWrongImgLog("noSrc", {
      pageUrl: `${location.host}${location.pathname}`,
      class: (element.getAttribute("class") || "").replace(/ /g, "##"),
    });
    return;
  }
  doConsole("添加重试队列", element);
  doLog("pushRetry", {
    src: element.getAttribute("src"),
  });

  retryImgQueue.push(element);

  // 3s后开始重试
  setTimeout(() => {
    doRetryImg(element, "polling");
  }, 3000);
}

function doRetryImg(element: HTMLImageElement, retryType: RetryType) {
  const src = element.getAttribute("src");

  // 如果图片没有失败，那么移除队列
  if (!isImgElementFail(element)) {
    deleteElementFromQueue(element);
    return;
  }

  // 如果图片正在加载中，那么不重试。防止多个重试机制产生冲突
  if (isImgElementLoading(element)) {
    return;
  }

  if (src && !hasImgReloadOverMaxCount(src, retryType)) {
    doConsole("重试图片，类型为", retryType);
    doLog("doRetry", {
      src,
    });
    (window as any).lib.redfoxReloadImg.reloadCount++;
    const newSrc = appendRetryFlag(src, retryType);

    element.onload = () => {
      deleteElementFromQueue(element);
      doLog("retrySuccess", { retryType, src: newSrc });
      doConsole("图片重试成功", element);
    };

    element.src = newSrc;
  }
}

function deleteElementFromQueue(element: HTMLImageElement) {
  const target = retryImgQueue.findIndex((v) => v === element);
  if (target > -1) {
    retryImgQueue.splice(target, 1);
  }
}

main();
